<template>
  <div class="paging">
    <button type="button" class="btn-paging first" @click="chgCurrentPage(1)">
      <span class="for-a11y">첫 페이지</span>
    </button>

    <button type="button" class="btn-paging prev" @click="chgCurrentPage(currentPage - 1)">
      <span class="for-a11y">이전 페이지</span>
    </button>

    <button type="button" 
      class="btn-paging" 
      :class="currentPage == num ? 'active': ''"
      v-for="(num, idx) in pageArray" :key="idx"
      @click="chgCurrentPage(num)"
    >{{num}}</button>
    
    <button type="button" class="btn-paging next" @click="chgCurrentPage(currentPage + 1)">
      <span class="for-a11y">다음 페이지</span>
    </button>

    <button type="button" class="btn-paging last" @click="chgCurrentPage(lastPage)">
      <span class="for-a11y">마지막 페이지</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalCount: Number,
    limit: Number,
    currentPage: Number,
  },
  created () {
    this.makeShowPagination()
  },
  data () {
    return {
      pageCount: 10,
      pageArray: [],
      lastPage: 0,
      listCount: this.limit,
    }
  },
  methods: {
    makeShowPagination () {
      this.pageArray = [];

      this.lastPage = Math.ceil(this.totalCount / this.listCount)

      let startNum = (this.currentPage - 1) - (this.currentPage - 1) % this.pageCount
      let lastNum = (this.currentPage - 1) - (this.currentPage - 1) % this.pageCount + this.pageCount;
      if(lastNum > this.lastPage) lastNum = this.lastPage

      for(let i=startNum; i<lastNum; i++) this.pageArray.push(i + 1);
    },
    chgCurrentPage (num) {
      if(num > this.lastPage) num = this.lastPage
      else if(num < 1) num = 1
      this.$emit('chgCurrentPage', num)
    },
  },
  watch: {
    'totalCount': function() {
      this.makeShowPagination()
    },
    'currentPage': function () {
      this.makeShowPagination()
    }
  }
}
</script>